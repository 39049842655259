<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Pedido - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form">
              <b-row>

                <b-col md="2">
                  <b-form-group label="N° Pedido :">
                    <b-form-input class="text-center" disabled v-model="order.order_number" ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Email :">
                    <b-form-input disabled type="email"  v-model="order.email" ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Moneda :">
                    <b-form-select disabled :options="currency_type"  v-model="order.currency_type" ></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Metodo de Pago :">
                    <b-form-input disabled v-model="order.payment_method" ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Comprobante:">
                    <b-form-select disabled :options="type_invoice"  v-model="order.type_invoice" ></b-form-select>
                  </b-form-group>
                </b-col>
           
                <b-col md="2">
                  <b-form-group label="Tipo de Documento :">
                    <b-form-select disabled v-model="order.document_type" :options="document_type"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Nro Documento :">
                    <b-input-group>
                      <b-form-input disabled v-model="order.document_number" class="form-control" ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
         
                <b-col md="5">
                  <b-form-group label="Nombres / Razón Social:">
                    <b-form-input disabled type="text" v-model="order.name"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Telefono:">
                    <b-form-input disabled type="text" v-model="order.phone"></b-form-input>
                  </b-form-group>
                </b-col>
      
                

                <b-col md="10">
                  <b-form-group label="Observación:">
                    <b-form-input disabled v-model="order.observation"></b-form-input>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Estado:">
                    <b-form-input v-if="order.state == 0" class="form-control text-center btn-danger" value="ANULADO" ></b-form-input>
                    <b-form-input v-if="order.state == 1" class="form-control text-center btn-warning" value="PENDIENTE" ></b-form-input>
                    <b-form-input v-if="order.state == 2" class="form-control text-center btn-info" value="EN PROCESO" ></b-form-input>
                    <b-form-input v-if="order.state == 3" class="form-control text-center btn-success" value="REALIZADO" ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <div class="table-responsive mt-1">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="3%" class="text-center">#</th>
                          <th width="10%" class="text-center">Codigo</th>
                          <th width="48%" class="text-center">Nombre</th>
                          <th width="10%" class="text-center">Fecha</th>
                          <th width="8%" class="text-center">Cantidad</th>
                          <th width="10%" class="text-center">P. Unit</th>
                          <th width="10%" class="text-center">P. Total</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in order_detail" :key="it">
                        <tr>
                          <td class="text-center">{{ it + 1 }}</td>
                          <td class="text-center"> {{ item.code }}</td>
                          <td class="text-left">{{ item.name }}</td>
                          <td class="text-center">{{ item.date }}</td>
                          <td class="text-center">{{ item.quantity }}</td>
                          <td class="text-right">{{ item.unit_price }}</td>
                          <td class="text-right">{{ item.total_price }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>

                <b-col md="9">
                </b-col>
                <b-col md="3">
                  <div class="table-responsive mt-1">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <td class="text-right"><strong>Subtotal :</strong></td>
                          <td class="text-right"><strong>{{order.subtotal}}</strong></td>
                        </tr>
                        <tr>
                          <td class="text-right"><strong>Descuento :</strong></td>
                          <td class="text-right"><strong>{{order.discount}}</strong></td>
                        </tr>
                        <tr>
                          <td class="text-right"><strong>Total :</strong></td>
                          <td class="text-right"><strong>{{order.total}}</strong></td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </b-col>
              </b-row>
              <b-row class="justify-content-center">
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/pedido/listar' }" append >Regresar <i class="far fa-arrow-alt-circle-left"></i></b-link>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import ApiQuery from "@/assets/js/APIQuery";
import LoadingComponent from './../pages/Loading'

export default {
  name: "ClientEdit",
  props: ["id_order"],
  components:{
    vSelect,
    Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  
  data() {
    return {
      module:'Order',
      isLoading: false,
      order: {
        id_order:'',
        id_customer:'',
        document_type:'',
        document_number:'',
        name:'',
        email:'',
        phone:'',
        order_number:'',
        type_invoice:'',
        currency_type:'',
        payment_method:'',
        file:'',
        observation:'',
        subtotal:'',
        discount:'',
        total:'',
        charge_code:'',
        charge_amount:'',
        state:'',
        state_change:'',
        registration_date:'',
      },
      order_detail:[],

      type_invoice: [
        {value: 'NV' , text : 'Nota de Venta'},
        {value: '03' , text : 'Boleta de Venta'},
        {value: '01' , text : 'Factura'},
      ],
      currency_type: [
        {value: 'PEN' , text : 'Soles'},
        {value: 'USD' , text : 'Dólares'},
      ],
      payment_method: [
        {value: 'Izipay' , text : 'Izipay'},
        {value: 'Paypal' , text : 'Paypal'},
        {value: 'Yape' , text : 'Yape'},
        {value: 'Plin' , text : 'Plin'},
      ],
      document_type: [
        {value: 1 , text : 'DNI'},
        {value: 6 , text : 'RUC'},
        {value: 4 , text : 'CARNET DE EXTRANJERIA'},
        {value: 7 , text : 'PASAPORTE'},
        {value: 0 , text : 'OTROS'},
      ],
      state:[
        {value: 1 , text : 'Activo'},
        {value: 0 , text : 'Inactivo'},
      ],
      //errors
      errors: {
        document_type: false,
        document_number: false,
        name: false,
      },

      error_document_number:'',
      validate: false,
    };
  },
  mounted() {
    this.ViewOrder();
  },
  methods: {
    ViewOrder,
    EditOrder,
    ValidateCancel,
    ValidateSuccess,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ViewOrder() {
  let me = this;
  let id_order = je.decrypt(me.id_order);
  let url = me.url_base + "order/view/" + id_order;
  axios({
    method: "GET",
    url: url,
    headers: {token: me.token, module: me.module, role: 3, },
  })
    .then(function (response) {
      if (response.data.status == 200) {

        me.order.id_order = response.data.result.order.id_order;
        me.order.id_customer = response.data.result.order.id_customer;
        me.order.document_type = response.data.result.order.document_type;
        me.order.document_number = response.data.result.order.document_number;
        me.order.name = response.data.result.order.name;
        me.order.email = response.data.result.order.email;
        me.order.phone = response.data.result.order.phone;
        me.order.order_number = response.data.result.order.order_number;
        me.order.type_invoice = response.data.result.order.type_invoice;
        me.order.currency_type = response.data.result.order.currency_type;
        me.order.payment_method = response.data.result.order.payment_method;
        me.order.file = response.data.result.order.file;
        me.order.observation = response.data.result.order.observation;
        me.order.subtotal = response.data.result.order.subtotal;
        me.order.discount = response.data.result.order.discount;
        me.order.total = response.data.result.order.total;
        me.order.charge_code = response.data.result.order.charge_code;
        me.order.charge_amount = response.data.result.order.charge_amount;
        me.order.state = response.data.result.order.state;
        me.order.registration_date = response.data.result.order.registration_date;
        me.order_detail = response.data.result.order_detail;

      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function EditOrder() {

  let me = this;
  let url = me.url_base + "order/edit";
  let data = me.order;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token, module: me.module, role: 3,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
          Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
          Swal.fire({ icon: 'warning', text: response.data.message, timer: 3000,})
      }
      me.ViewOrder();
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function ValidateCancel() {
 
 this.order.state_change = 0;
 this.validate = false;
  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de anular el pedido ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.EditOrder();
      }
    });

  }

}

function ValidateSuccess() {
 
 this.order.state_change = 2;
 this.validate = false;
  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de Realizar el pedido ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.EditOrder();
      }
    });

  }

}
</script>
